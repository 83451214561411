export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";

//APIs ACTION TYPES
export const SIGN_IN = "SIGN_IN";
export const SIGN_OUT = "SIGN_OUT";
export const SIGN_UP = "SIGN_UP";

export const ADD_CATEGORY = "ADD_CATEGORY";
export const SHOW_CATEGORY = "SHOW_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";

// export const ADD_ORDERS = "ADD_ORDERS";
// export const SHOW_ORDERS_USER_ID = "SHOW_ORDERS_USER_ID";
// export const SHOW_ORDERS = "SHOW_ORDERS";

export const ADD_PRODUCT = "ADD_PRODUCT";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const SHOW_PRODUCTS = "SHOW_PRODUCTS";
export const SHOW_LATEST_PRODUCTS = "SHOW_LATEST_PRODUCTS";
export const SHOW_PRODUCTS_BY_CATEGORY_ID = "SHOW_PRODUCTS_BY_CATEGORY_ID";
export const SHOW_PRODUCTS_BY_PRODUCT_ID = "SHOW_PRODUCTS_BY_PRODUCT_ID";

export const ADD_IMAGES = "ADD_IMAGES";
export const SHOW_IMAGES = "SHOW_IMAGES";
export const UPDATE_IMAGES = "UPDATE_IMAGES";


export const ADD_ORDERS = "ADD_ORDERS";
export const SHOW_ALL_ORDERS = "SHOW_ALL_ORDERS";
export const SHOW_ORDERS_BY_CUSTOMER_ID = "SHOW_ORDERS_BY_CUSTOMER_ID";
export const UPDATE_ORDER_STATUS = "UPDATE_ORDER_STATUS";