import React from "react";
import { Route, Redirect } from "react-router-dom";
const ProtectedRoutes = ({ component: Component, ...rest }) => {
  let isSignedIn = false;
  const type = localStorage.getItem("userId");
  if (type) {
    isSignedIn = true;
  }
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isSignedIn) {
          return <Component {...props} />;
        } else {
          return <Redirect to="/auth/signin" />;
        }
      }}
    />
  );
};

export default ProtectedRoutes;
